import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["levelOption", "brandQuestion", "productQuestion"]

  connect() {
    // Check initial state of radio buttons
    this.levelOptionTargets.forEach(option => {
      if (option.checked) {
        this.toggleQuestions(option)
      }
    })
  }

  toggleQuestions(event) {
    const selectedOption = event.target || event
    const optionType = selectedOption.value.toLowerCase()

    if (optionType === "brand") {
      this.brandQuestionTarget.hidden = false
      this.productQuestionTarget.hidden = true
    } else if (optionType === "product") {
      this.brandQuestionTarget.hidden = true
      this.productQuestionTarget.hidden = false
    }
  }
} 
