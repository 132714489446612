import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import { getMetaValue, removeElement, insertAfter } from "./helpers";

/**
 * Stimulus controller for handling file uploads with preview functionality.
 * Supports both PDF and image file types with custom preview handling.
 * Integrates with Rails ActiveStorage for direct uploads.
 */
export default class extends Controller {
  static targets = ["input", "pdfPreview", "imagePreview", "previewContainer", "uploadArea", "errorMessage"];
  static values = { showPreview: Boolean };

  connect() {
    if (this.element.dropzone) return;

    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    this.bindFormSubmit();
  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  // Core event handling
  bindEvents() {
    this.dropZone.on("addedfile", file => this.handleAddedFile(file));
    this.dropZone.on("error", (file, errorMessage) => this.handleError(file, errorMessage));
    this.dropZone.on("removedfile", file => this.handleRemovedFile(file));
    this.dropZone.on("canceled", file => file.controller?.xhr.abort());
  }

  /**
   * Primary handler for newly added files.
   * Validates file type and initiates preview if enabled.
   */
  handleAddedFile(file) {
    this.hideError();
    const isAccepted = this.isValidFileType(file.type);

    if (isAccepted && this.showPreviewValue) {
      this.handleFilePreview(file);
    } else if (!isAccepted) {
      this.showError("Only PDF and image files are accepted.");
      this.dropZone.removeFile(file);
    }
  }

  /**
   * Handles file acceptance and initiates DirectUpload.
   * Called by Dropzone's accept callback.
   */
  handleAccept(file, done) {
    if (!this.isValidFileType(file.type)) {
      done("Only PDF and image files are accepted.");
      return;
    }
    done();
    createDirectUploadController(this, file).start();
  }

  handleError(file, errorMessage) {
    this.showError(errorMessage);
    this.dropZone.removeFile(file);
  }

  handleRemovedFile(file) {
    if (!file.controller) return;
    removeElement(file.controller.hiddenInput);
    this.hidePreview();
  }

  // File preview handling
  handleFilePreview(file) {
    if (!file?.type) return;

    if (file.type === "application/pdf") {
      this.showPdfPreview(file);
    } else if (file.type.startsWith('image/')) {
      this.showImagePreview(file);
    }
  }

  showPdfPreview(file) {
    if (!this.hasPdfPreviewTarget) return;

    const objectUrl = URL.createObjectURL(file);
    this.pdfPreviewTarget.src = objectUrl;
    this.pdfPreviewTarget.classList.remove('d-none');
    this.imagePreviewTarget?.classList.add('d-none');
    this.showPreviewContainer();
    this.hideUploadArea();

    // Clean up object URL after preview loads
    this.pdfPreviewTarget.onload = () => URL.revokeObjectURL(objectUrl);
  }

  showImagePreview(file) {
    if (!this.hasImagePreviewTarget) return;

    const reader = new FileReader();
    reader.onload = e => {
      this.imagePreviewTarget.src = e.target.result;
      this.imagePreviewTarget.classList.remove('d-none');
      this.pdfPreviewTarget?.classList.add('d-none');
      this.showPreviewContainer();
      this.hideUploadArea();
    };
    reader.readAsDataURL(file);
  }

  isValidFileType(type) {
    return ['application/pdf', 'image/jpeg', 'image/png'].includes(type);
  }

  // UI Methods
  showPreviewContainer() {
    this.previewContainerTarget.classList.remove('d-none');
  }

  hideUploadArea() {
    this.uploadAreaTarget.classList.add('d-none');
  }

  showUploadArea() {
    this.uploadAreaTarget.classList.remove('d-none');
  }

  replaceFile() {
    this.clearPreviews();
    this.showUploadArea();
    this.hideError();
  }

  hidePreview() {
    this.clearPreviews();
    this.showUploadArea();
  }

  clearPreviews() {
    if (this.hasPdfPreviewTarget) {
      this.pdfPreviewTarget.src = '';
      this.pdfPreviewTarget.classList.add('d-none');
    }
    if (this.hasImagePreviewTarget) {
      this.imagePreviewTarget.src = '';
      this.imagePreviewTarget.classList.add('d-none');
    }
    this.previewContainerTarget.classList.add('d-none');
  }

  showError(message) {
    if (!this.hasErrorMessageTarget) return;

    const errorText = this.errorMessageTarget.querySelector('[data-dropzone-target="errorText"]');
    if (errorText) {
      errorText.textContent = message;
      this.errorMessageTarget.classList.remove('d-none');
    }
  }

  hideError() {
    this.errorMessageTarget?.classList.add('d-none');
  }

  // Getters
  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 50;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  // Configuration and utilities
  get dropzoneConfig() {
    return {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: this.addRemoveLinks,
      autoQueue: false,           // Disabled as we handle queueing via DirectUpload
      previewsContainer: false,   // Disabled as we handle previews manually
      dictInvalidFileType: "Only PDF and image files are accepted.",
      dictFileTooBig: "File is too large ({{filesize}}MB). Maximum file size: {{maxFilesize}}MB.",
      dictMaxFilesExceeded: "Maximum number of files exceeded.",
      autoProcessQueue: false,    // Disabled as we handle processing via DirectUpload
      accept: (file, done) => this.handleAccept(file, done)
    };
  }

  // Form handling
  bindFormSubmit() {
    const form = this.element.closest('form');
    if (!form) return;

    form.addEventListener('submit', () => {
      // Clean up empty file inputs before form submission
      const fileInputs = form.querySelectorAll(`input[name="${this.inputTarget.name}"]`);
      fileInputs.forEach(input => {
        if (!input.value) input.remove();
      });
    });
  }
}

// DirectUpload handling
class DirectUploadController {
  constructor(source, file) {
    this.directUpload = new DirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
    this.file.controller = this;
  }

  start() {
    // Remove any existing hidden input with the same name
    const existingInput = document.querySelector(`input[name="${this.source.inputTarget.name}"]`);
    if (existingInput && existingInput !== this.source.inputTarget) {
      existingInput.remove();
    }

    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event => {
      const progress = (event.loaded / event.total) * 100;
    });
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
    this.source.showError(error);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

// Helper functions
function createDropZone(controller) {
  Dropzone.autoDiscover = false;
  return new Dropzone(controller.element, controller.dropzoneConfig);
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}
