import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "container"]

  connect() {
    // Set initial state
    if (this.inputTarget.checked) {
      this.containerTarget.classList.add("color-bg-accent")
      this.containerTarget.classList.add("border")
      this.containerTarget.classList.add("border-accent")
      this.containerTarget.classList.remove("color-border-default")
    }

    // Add hover event listeners (FIXME: Primer's hover util classes not working)
    this.containerTarget.addEventListener('mouseenter', () => {
      if (!this.inputTarget.checked) {
        this.containerTarget.classList.add("color-bg-subtle")
      }
    })

    this.containerTarget.addEventListener('mouseleave', () => {
      if (!this.inputTarget.checked) {
        this.containerTarget.classList.remove("color-bg-subtle")
      }
    })
  }

  toggle() {
    const isChecked = this.inputTarget.checked

    if (isChecked) {
      this.containerTarget.classList.add("color-bg-accent")
      this.containerTarget.classList.remove("color-bg-subtle")  // Remove hover state if checked
    } else {
      this.containerTarget.classList.remove("color-bg-accent")
    }

    // If it's a radio button, remove selected classes from other inputs in the same group
    if (this.inputTarget.type === "radio") {
      const groupName = this.inputTarget.name
      const otherInputs = document.querySelectorAll(`input[name="${groupName}"]:not(:checked)`)
      otherInputs.forEach(input => {
        const container = input.closest('[data-border-box-input-target="container"]')
        if (container) {
          container.classList.remove("color-bg-accent")
        }
      })
    }
  }
}
