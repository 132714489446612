import { Controller } from '@hotwired/stimulus'
import * as analytics from '../../utils/analytics'

export default class extends Controller {
  mouseenter(event) {
    analytics.track(
      'Popup tip opened', 
      {
        title: this.string,
        href: window.location.href
      }
    )

    analytics.track(
      'popupTip_opened', 
      {
        object: 'stringTip',
        action: 'opened',
        title:  this.string
      },
      { adapter: 'segment' }
    )
  }
}
