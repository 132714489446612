import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "criteriaButton",
    "evidenceButton",
    "radioGroup",
    "checkboxGroup",
    "criteriaContent",
    "evidenceContent",
    "levelOption",
    "typeInput"
  ]

  connect() {
    this.updateButtonState()
  }

  updateButtonState() {
    const isAnswered = this.isQuestionAnswered()
    this.criteriaButtonTarget.disabled = !isAnswered
  }

  isQuestionAnswered() {
    // Find the visible group (either radio or checkbox)
    const visibleRadioGroup = this.radioGroupTargets.find(group => !group.closest('[hidden]'))
    const visibleCheckboxGroup = this.checkboxGroupTargets.find(group => !group.closest('[hidden]'))

    if (visibleRadioGroup) {
      // For radio buttons, one must be selected
      return visibleRadioGroup.querySelector('input[type="radio"]:checked') !== null
    }

    if (visibleCheckboxGroup) {
      // For checkboxes, all must be checked
      const checkboxes = visibleCheckboxGroup.querySelectorAll('input[type="checkbox"]')
      return Array.from(checkboxes).every(checkbox => checkbox.checked)
    }

    return false
  }

  handleInputChange() {
    this.updateButtonState()
  }

  showEvidence() {
    // Get the selected type if there's a level question
    const selectedLevel = this.levelOptionTargets.find(radio => radio.checked)
    if (selectedLevel && this.hasTypeInputTarget) {
      this.typeInputTarget.value = selectedLevel.value.toLowerCase()
    }

    this.criteriaContentTarget.hidden = true
    this.evidenceContentTarget.hidden = false
    this.criteriaButtonTarget.hidden = true
    this.evidenceButtonTarget.hidden = false
  }
}
